.text-center,
.modal-container {
  padding: 15px;
  overflow: hidden;
  > h2 {
    font-weight: bold;
    font-style: normal;
    line-height: 37px;
    color: #333333;
  }
  a {
    text-decoration: underline;
  }
  .body-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    > div {
      flex: 1 1;
      .Mui-selected {
        text-decoration: underline;
        color: #2290d1;
      }
      .MuiTab-root {
        font-family: inherit;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-transform: inherit;
      }
      .PrivateTabIndicator-root-1 {
        display: none;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    > div {
      justify-content: space-evenly;
      > button {
        margin-right: 10px;
        background-color: #8fcaed;
        color: #333437;
      }
    }
    > button {
      font-family: inherit;
      color: #333437;
      font-weight: bold;
      text-align: center;
      border-radius: 5px;
    }
  }

  .btn-delete {
    button {
      margin-left: 10px;
      background-color: #8fcaed;
      color: #333437;
    }
  }
  @media (max-width: 700px) {
    .body-modal {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
    .responsiveDelete {
      flex-direction: column;
      flex-flow: column-reverse;
      gap: 10px;
      width: 100%;
      .btn-delete {
        width: 100%;
        button {
          margin-left: 0px;
          width: 100%;
        }
      }
      > button {
        width: 100%;
      }
    }
    .buttons {
      flex-direction: column;
      flex-flow: column-reverse;
      gap: 10px;
      > button {
        width: 100% !important;
      }
      > div {
        width: 100%;
        > button {
          width: 100%;
        }
      }
    }
  }
}

.archive {
  width: 700px;
}
