// variables
$lighterSkyBlue: #e8f5fb;
$skyBlue: #d0eefb;
$bodyFontColor: #555555;

// importing external scss files
@import "navbar";
@import "login";
@import "userlist";
@import "chartrange";
@import "halfpagelayout";
@use "variables";

.MuiFormControlLabel-label {
  align-self: stretch;
  align-items: center;
  display: flex;
}
.MuiFormControlLabel-labelPlacementStart {
  .MuiFormControlLabel-label {
    margin-right: 0.25em;
  }
}
#loading-dialog {
  z-index: 2201;
  color: #ffffff;
}
.full-width,
.w-100 {
  width: 100%;
}

.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-80 {
  width: 80%;
}
