#footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat";
  flex-direction: column;
  font-style: normal;
  .divider {
    border: 2px solid rgba(196, 196, 196, 0.2);
  }
  .div-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
    @media (max-width: 600px) {
      & {
        flex-direction: column-reverse;
      }
    }
    > ul {
      padding: 0px !important;
    }
    .list-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      list-style: none;
      gap: 10px;
      @media (max-width: 600px) {
        & {
          flex-direction: row;
        }
      }
      > li {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        > a {
          text-decoration: none;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #e38536;
        }
      }
    }

    .list-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        background: #e38536;
        border-radius: 18.5px;
        width: 191px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 18.5px;
        line-height: 23px;
        color: #ffffff;
      }
      a {
        text-decoration: none;
      }
      .social-media {
        margin: 10px 0px 10px;
        a {
          text-decoration: none;
        }
        .social {
          color: #e38536;
          text-decoration: none;
          font-size: 40px;
        }
      }
    }
  }

  .copyright-terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #3c3844;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    @media (max-width: 600px) {
      & {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }
  }
}
