$nav-item-bg-color: #8fcaed;
$nav-item-text-color: black;

.navbar-mobile-links-container {
  margin-top: 20%;
  display: flex;
  flex-flow: column nowrap;
}

.navbar-mobile-logo {
  align-self: center;
  width: 75%;
  margin-bottom: 2em;
}

.navbar-mobile-links-container h2 {
  font-size: 1.5em;
  text-decoration: none;
  background-color: $nav-item-bg-color;
  color: $nav-item-text-color;
  font-weight: normal;
  padding: 0.75em 1.5em 0.75em 1.5em;
  text-align: center;
  border-radius: 5px;
}
