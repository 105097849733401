@import "../../styles/v2/variables";

$medium-screen-size: 768px;
@mixin medium-screen {
  @media only screen and (min-width: $medium-screen-size) {
    @content;
  }
}

body {
  background-color: $blue-100;
  font-family: $font-family-main;
}

legend {
  float: none !important;
}

.container-nav {
  position: fixed;
  background-color: $blue-300;
}

.container-content {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  overflow-x: hidden;
  &.full {
    margin-left: 6rem !important;
    width: calc(100% - 6rem);
  }
  margin-left: 22rem;
  width: calc(100% - 22rem);
}

.container-header {
  position: absolute;
  right: 0;
}

.container-page {
  flex-grow: 1;
}

// mobile first design (at mobile view 100%)
.app-container {
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
  width: 100%;
  float: right;
  min-height: 100vh;
  margin-top: 120px;
}

a {
  text-decoration: none;
  color: $blue-500;
  &:hover {
    color: $blue-400;
  }
}

button {
  font-family: $font-family-main;
}
.app-container-flex-item {
  width: 100%;
}

.MuiFab-root {
  box-shadow: none !important;
}

.MuiSelect-select {
  .MuiInputBase-input {
    color: #747171;
    padding: 6px 10px 6px 7px !important;
  }
  .MuiSelect-selectMenu {
    background: #ffffff;
    border-radius: 7px !important;
    border: 1px solid #ccc !important;
  }
  .MuiSelect-select:focus {
    background-color: #ffff;
  }
}

// at bigger screen makes 85% content and 15% for the navbar (refer to navbar.scss)

@include medium-screen {
  .app-container {
    display: flex;
    flex: column nowrap;
    width: 83%;
    float: right;
    min-height: 100vh;
  }
}

.MuiInput-underline:before,
.MuiInput-underline:focus,
.MuiInput-underline:after {
  border: none !important;
}
