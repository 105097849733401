@mixin medium-screen {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @mixin lg-screen {
    @media only screen and (min-width: 1060px) {
      @content;
    }
  }
  
  @font-face {
    font-family: Poppins;
    src: url(../../assets/fonts/poppins-regular.ttf);
  }

#billing  {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #000000;

    .patient-grid {
        padding: 1.2em;
        position: relative;
        font-family: Poppins;
        font-style: normal;
        letter-spacing: 0em;
        font-weight: 600;
        text-align: left;
      }
    .patient-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
      #section-footer {
        padding-bottom: 0px;
      }
      #section-template {
        margin-top: 20px;
      }
      @include medium-screen {
        .patient-grid {
          display: grid;
          &#section-a,
          &#section-b {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .patient-flex {
          display: flex;
          flex-direction: row;
        }
      }
    
      .view-plans {
        color: #4EA5CD;
      }

      .upgrade-plans{
        text-decoration:  underline;
        color: black;
      }

      .your-subscription {
        background: #C4DFEF;
        border-radius: 10px;
        width: auto;
        height: auto;
        padding: 20px;
        font-family: Montserrat;
        margin: 10px;
        p {
          color: #000000;
        }
        .subscription {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;          
        }
        .month {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 45px;
          margin-bottom: 0px;
        }
        .sub-title {
          opacity: 0.7;
        }
        .sub-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .sub-item > div:nth-child(2) > div {
          padding-left: 30px;
        }
        .month-center{
          text-align: center;
        }
      }

      @include medium-screen {
        .your-subscription {
          width: 736px;
          margin: 0px ;
          height: auto;
        }
      }

      @include lg-screen {
        .your-subscription {
          width: 636px;
          height: 191px;
          margin: 0px;
        }
      }

      .next-billing {
        width: auto;
        padding: 20px;
        height: auto;
        background: #C4DFEF;
        border-radius: 10px;
        margin: 5px 30px;
        font-family: Montserrat;
        .subscription {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;          
        }
        .month {
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 43px;
          margin-bottom: 0px;
        }
        .view-details { 
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
         
          a { 
            text-decoration: underline;
            color: #000000;
            padding-left:20px ;
          }
          p { 
            margin-bottom: 0px;
          }
        }
      }
      @include medium-screen {
        .next-billing {
          width: 380px;
          margin: 0px 30px;
          height: 148px;
        }
      }

      #section-template_1 {
       > a {
        color: #000000;
        font-family: Montserrat;
        font-size: 16px;
       } 
      }

      
}
 
.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Montserrat;
  font-style: normal;
  .sucess-msg {
    width: 890px;
    height: 313px;
    margin-top: 100px;
    background: #CBE9F6;
    border-radius: 5px;
  }
  p {
    text-align: center;
    vertical-align: middle;
    margin-top: 100px;
    font-weight: 600;
    font-size: 20.5742px;
    line-height: 25px;
    text-align: center;
    color: #333437;
  }
  a {
    text-decoration: underline;
    color: #333437;
  }
}