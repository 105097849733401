@mixin medium-screen {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin semi-medium-screen {
  @media only screen and (min-width: 987px) {
    @content;
  }
}

$nav-item-bg-color: #8fcaed;
$nav-item-text-color: #333437;

@font-face {
  font-family: Montserrat;
  src: url(../../../assets/fonts/montserrat_wght.ttf);
}

.navbar-container {
  display: none;
}

.navbar-links-container {
  // margin-top: 20%;
}
.navbar-links-container h2 {
  font-size: 0.75em;
  text-decoration: none;
  background-color: $nav-item-bg-color;
  color: $nav-item-text-color;
  font-weight: bold;
  padding: 0.75em 0.6em 0.75em 0.6em;
  text-align: center;
  border-radius: 5px;
  margin: 25px 0;
  font-family: Montserrat;
}

// when it its 768px or more
@include medium-screen {
  .navbar-container {
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    width: 15%;
    height: 100%;
    z-index: 1300;
    background-color: #c4dfef;
    // box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
}

// make font smaller at about 850px (prevent overflow)
@include semi-medium-screen {
  .navbar-container h2 {
    font-size: 15px;
  }
  .navbar-links-container {
    // margin-top: 20%;
    margin: 0px 15px 10px 15px;
  }
  .navbar-container {
    align-items: center;
  }
}
