.half-page-container {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, white 50%, $skyBlue 50%);
  // background: red;
  display: flex !important;

  .half-page-grid-container {
    align-items: center;
    // max-width: 1440px;
    // margin: auto;

    .MuiGrid-item {
      height: auto;
      padding: 0 1em;

      img {
        height: 100%;
        max-width: 7.5rem;
        width: auto;
        margin-right: 10px;
      }

      .MuiTypography-h1 {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: 2px;
      }
    }

    .half-page-primary {
      width: 100%;
      max-width: 32rem;
      margin: auto;

      .MuiTypography-h2 {
        font-weight: bold;
        font-size: 2rem;
      }

      form {
        // background-color: red;
        display: flex;
        flex-flow: column;
        width: 100%;

        input {
          background-color: #f5f5f5;
        }

        button {
          background-color: orange;
          color: #ffffff;
          margin-right: 0;
          width: 10rem;
          align-self: flex-end;
        }
      }
    }
  }

  // }
}

@media screen and (max-width: 959px) {
  .half-page-container {
    // background: linear-gradient(180deg, white 50%, #d0eefb 50%);
    background: linear-gradient(0deg, white 65%, $skyBlue 35%);

    .half-page-grid-container {
      .half-page-secondary {
        order: 1;
      }

      .half-page-primary {
        order: 2;
        height: 35%;

        form {
          width: 100%;
        }

        .MuiGrid-item {
          width: 100%;
        }
      }
    }
  }
}
