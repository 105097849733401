.UserColumn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  min-width: 125px;

  > div {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background: white;
    padding: 15px 10px;
    width: 100%;
  }
  > div:last-child {
    border-bottom: 1px solid #f0f2f3;
  }
  .header {
    min-height: 54px;
    background: rgba(196, 223, 239, 0.5);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2290d1;
  }
  .body {
    flex: 1;
    min-height: 46px;
    border-top: 1px solid #f0f2f3;
  }
}
.selected {
  background: rgba(0, 158, 255, 0.1) !important;
  border: 1px solid #2290d1 !important;
  > div {
    background: rgba(0, 158, 255, 0.1) !important;
  }
  pointer-events: none;
}
