@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/poppins-regular.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(../assets/fonts/montserrat_wght.ttf);
}

@font-face {
  font-family: MontserratBold;
  src: url(../assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(../assets/fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans-SemiBold;
  src: url(../assets/fonts/OpenSans-SemiBold.ttf);
}


body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0 auto;
}

.MuiFormControlLabel-labelPlacementStart {
  padding-right: 1em;
}

.memory-line {
  stroke: rgb(255, 127, 14);
  opacity: 0.25;
  stroke-width: 4px;
  cursor: none;
  fill: none;
}

.memory-line-errorbar {
  stroke: rgb(255, 127, 14);
  opacity: 0.25;
  stroke-width: 1.5px;
  cursor: none;
  fill: none;
}

.memory-line:hover {
  opacity: 1;
  stroke-width: 4px;
}

.attention-line {
  stroke: rgb(31, 119, 180);
  opacity: 0.25;
  stroke-width: 4px;
  cursor: none;
  fill: none;
}

.attention-line-errorbar {
  stroke: rgb(31, 119, 180);
  opacity: 0.25;
  stroke-width: 1.5px;
  cursor: none;
  fill: none;
}

.attention-line:hover {
  opacity: 1;
  stroke-width: 4px;
}

.processing-line {
  stroke: rgb(44, 160, 44);
  opacity: 0.25;
  stroke-width: 4px;
  cursor: none;
  fill: none;
}

.processing-line-errorbar {
  stroke: rgb(44, 160, 44);
  opacity: 0.25;
  stroke-width: 1.5px;
  cursor: none;
  fill: none;
}

.processing-line:hover {
  opacity: 1;
  stroke-width: 4px;
}

.line-text {
  font-size: 20px;
}

.attention-circle {
  fill: rgb(31, 119, 180);
  transition: ease-in-out 0.5s all;
  position: relative;
}

.memory-circle {
  fill: rgb(255, 127, 14);
  transition: ease-in-out 0.5s all;
  position: relative;
}

.processing-circle {
  fill: rgb(44, 160, 44);
  transition: ease-in-out 0.5s all;
  position: relative;
}

.circle-text {
  font-size: 14px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10000;
  fill: black;
}

.PhoneInput {
  height: 50px;
  width: 28rem;
  border-radius: 5px;
  border: 1px solid rgb(182, 181, 181);
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  height: 40px;
  width: 15px;
  border: 1px solid rgb(182, 181, 181);
}
