@import "../../../../styles/v2/variables";

@mixin medium-screen {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin semi-medium-screen {
  @media only screen and (min-width: 987px) {
    @content;
  }
}

.container-nav-inner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1.5rem 20px;
  &.collapsed {
    width: 22rem;
    .navbar-links-container {
      margin: 1.5rem 0;
    }
  }
  &.collapsed {
    width: 6rem;
  }
}

.container-nav-logo {
  display: flex;
  align-items: center;

  & > .logo {
    width: 57px;
    height: 57px;
  }
  & > .logotype {
    width: 76px;
    height: 22px;
    margin-left: 20px;
  }
}

.navbar-links-container {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  flex-grow: 0;
  font-size: 1.25rem;

  &.collapse-toggle {
    flex-grow: 1;
    justify-content: end;
  }
}

.navbar-link {
  padding: 0.75rem;
  color: $gray-600;
  &:hover {
    color: $blue-500;
    // uncomment to allow icon border to color on hover
    // currently other icons are uncolored svgs that do not color on hover
    // .circle {
    //   border-color: $blue-400;
    // }
  }
  // &:hover .circle {

  // }
  & > img,
  svg {
    margin-right: 10px;
  }
  &.active {
    background-color: $blue-400;
  }
}

.nav-boundary {
  border-top: 2px solid $blue-200;
}

.circle {
  border: 1px solid;
  border-radius: 1.25rem;
  border-color: $gray-600;
  color: $gray-600;
}
