@import "../../../styles/v2/variables";

.header h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  margin-top: 27px;
  position: absolute;
  left: 8px;
  top: 30px;
}
.header h6:hover {
  border-bottom: 2px solid #333437;
}

.header h6:nth-child(2) {
  left: 200px !important;
}

.header h6 a {
  text-decoration: none;
  margin-left: 0 !important;
  color: #333437;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}
@media (min-width: 767px) {
  .mobileDropDown {
    display: none;
  }
}
.mobileDropDown {
  box-shadow: 0 4px 2px -2px gray;
}

.circle-icon { 
  width: 2rem;
  border-radius: 2rem;
  line-height: 2rem;
  display: inline-block;
  text-align: center;
  background-color: $blue-200;
  color: $gray-600;
}

.bar-item {
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
}