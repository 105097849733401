.nf-chart-range {
  display: flex;
  flex-direction: row !important;

  label {
    border: 2px solid $skyBlue;
    position: relative;
    margin: auto;
    border-radius: 5px;

    .MuiFormControlLabel-label {
      margin: auto;
      padding: 5px;
    }
  }
}

//

//     label:hover{
//         background-color: pink;

//     }

//     input[type="radio"]{
//         background-color: red !important;
//     }

// }
