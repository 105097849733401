.dialog-content {
  .MuiInputLabel-formControl,
  .MuiFormLabel-root {
    transform: none;
    color: #333437;
    font-weight: 500;
  }
  .MuiInputBase-input {
    color: #333437;
    background-color: #e2eff9;
    border-radius: 10px;
    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.48), 0px 0px 4px rgba(0, 0, 0, 0.12);
    padding: 12px;
  }
  .MuiInput-underline:before {
    content: none;
  }
  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 4px 10px;
    text-align: left;
  }
  .MuiStep-completed img {
    border: 6px solid #c4dfef;
    border-radius: 50%;
    margin-bottom: 30px;
  }

  .buttons-individual-step-3 {
    margin-top: 0px;
  }

}
.MuiPickersModal-dialogRoot {
  .MuiPickersToolbar-toolbar,
  .MuiPickersDay-daySelected {
    background-color: #8fcaed;
  }
  .MuiButton-textPrimary {
    color: #8fcaed;
  }
}
