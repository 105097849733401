.UploadingResult-container {
  .title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.12px;
    color: #333437;
    padding: 10px 15px;
    margin-bottom: 30px;
  }
  main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .main-header {
      display: flex;
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.12px;
      color: #333437;
    }
    .main-content {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-height: 300px;
      .error-header {
        display: flex;
        width: 100%;
        .cell-header {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 54px;
          background: rgba(196, 223, 239, 0.5);
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #2290d1;
          padding: 15px 10px;
          width: 100%;
        }
      }
    }
    ::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.02);
      box-shadow: inset 0.5px 0px 0px rgba(0, 0, 0, 0.07),
        inset 1px 0px 0px rgba(0, 0, 0, 0.02),
        inset -1px 0px 0px rgba(0, 0, 0, 0.02),
        inset -0.5px 0px 0px rgba(0, 0, 0, 0.07);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.51);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.next-forUploadingResult {
  position: absolute !important;
  right: 7px !important;
  bottom: -57px !important;
  width: 109px !important;
}
