.SuccessComponent-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 60px 120px;
  @media (max-width: 860px) {
    margin: 20px;
    .main-content {
      font-size: 27px !important;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .main-content {
      padding: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #333437;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}
