.InvalidInputRow-container {
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  > div:last-child {
    border-bottom: 1px solid #f0f2f3;
  }
  .cell-content {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background: white;
    padding: 15px 10px;
    width: 100%;
    border-top: 1px solid #f0f2f3;
    #input {
      border: none !important;
      outline: none !important;
      border: 1px solid #eee;
      width: 150px !important;
    }
    #select {
      border: none !important;
      outline: none !important;
      border: 1px solid #eee !important;
      width: 150px !important;
    }
  }

  .invalidCell {
    background: #e9d4d4 !important;
    color: #d12d22 !important;
    border-bottom: 1px solid #e9d4d4;
    border-top: 1px solid #e9d4d4;
    .cell-content {
      background: #e9d4d4 !important;
    }
  }
}
