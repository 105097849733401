@mixin medium-screen {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

.notEnoughNF-container{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  main {
      padding: 10px;
      font-family: Montserrat;
  }
  .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      font-family: Montserrat;

    .cancel {
      box-shadow: 0px 1.68565px 1.68565px rgba(143, 202, 237, 0.25);
      padding: 10.1139px 21.9134px;
      background: #ffffff;
      border: 0.5px solid #7a7585;
    }
    .upgrad { 
      background: #8FCAED;
      border-radius: 5px;
      width: 220px;
      height: 41px;
    }
  }
}

@include medium-screen() {
  
  .notEnoughNF-container{ 
    width: 506px;
    height: 223px;
  }
  main {
    padding: 30px;
  }

  .buttons {
    gap: 40px;
  }
}
