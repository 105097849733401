.login-page-container {
  position: relative;
  margin: auto;
  width: 100%;

  // #tsparticles{
  //     position: absolute;
  // }

  .nf-login-page {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, white 50%, $skyBlue 50%);
    // background: red;
    display: flex !important;

    // main{
    //     align-self: center;
    //     max-width: none;

    .nf-login-form-grid-container {
      align-items: center;
      // max-width: 1440px;
      // margin: auto;

      .MuiGrid-item {
        height: auto;
        padding: 0 1em;
        img {
          height: 100%;
          max-width: 7.5rem;
          width: auto;
          margin-right: 10px;
        }
        .MuiTypography-h1 {
          font-size: 3rem;
          font-weight: bold;
          letter-spacing: 2px;
        }
      }

      .MuiGrid-item:first-child {
        width: 100%;
        max-width: 32rem;
        margin: auto;
        .MuiTypography-h2 {
          font-weight: bold;
          font-size: 2rem;
        }

        form {
          // background-color: red;
          display: flex;
          flex-flow: column;
          input {
            background-color: #f5f5f5;
          }
          button {
            background-color: orange;
            color: #ffffff;
            margin-right: 0;
            width: 10rem;
            align-self: flex-end;
          }
        }
      }
      .MuiGrid-item:last-child {
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
      }
    }
    // }
  }
}

@media screen and (max-width: 959px) {
  .login-page-container {
    .nf-login-page {
      // background: linear-gradient(180deg, white 50%, #d0eefb 50%);
      background: linear-gradient(0deg, white 65%, $skyBlue 35%);

      // main{
      //     height: 100%;

      .nf-login-form-grid-container {
        height: 100%;

        .MuiGrid-item {
          padding: 0;
          width: 100%;
        }

        .MuiGrid-item:first-child {
          order: 2;
          align-content: flex-end;
          margin-top: 2.5rem;
        }
        .MuiGrid-item:last-child {
          order: 1;
        }
      }
      // }
    }
  }
}
