.ConfirmInput-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .allTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .title {
      text-align: left;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36.57px;
      letter-spacing: 0.12px;
      text-align: center;
      color: #333437;
    }
    .sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.5px;
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
    button:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
    .cancel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10.1139px 21.9134px;
      background: #ffffff;
      border: 0.5px solid #7a7585;
      box-shadow: 0px 1.68565px 1.68565px rgba(143, 202, 237, 0.25);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .back {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10.1139px 21.9134px;
      background: #ffffff;
      border: 0.5px solid #7a7585;
      box-shadow: 0px 1.68565px 1.68565px rgba(143, 202, 237, 0.25);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .next {
      background: #8fcaed;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10.1139px 21.9134px;
      border: 0.5px solid #7a7585;
      box-shadow: 0px 1.68565px 1.68565px rgba(143, 202, 237, 0.25);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .columns {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    padding: 5px;
    max-height: 270px;
    max-width: 1044px;
  }
  .MuiStepper-horizontal {
    max-height: 270px;
    max-width: 1044px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0.5px 0px 0px rgba(0, 0, 0, 0.07),
      inset 1px 0px 0px rgba(0, 0, 0, 0.02),
      inset -1px 0px 0px rgba(0, 0, 0, 0.02),
      inset -0.5px 0px 0px rgba(0, 0, 0, 0.07);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.51);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .total-user {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    text-align: right;
  }
}
