@mixin medium-screen {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

// mobile first default
.stepTwoGridItem {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: start;
  align-items: center;

  background-color: #84a9d0;
}

.tip {
  align-self: flex-end;
  margin: 4px 12px 0 0;
  cursor: default;
  text-decoration: none;
  color: whitesmoke;
  font-size: 1.25em;
}
.tip:hover {
  text-decoration: none;
}

.subscription_description {
  color: white;
}
// target how many users paragraph element
.subscription_description > p:first-child {
  text-align: center;
}

#monthly_section b {
  color: #a54747;
}
#annually_section b {
  color: #a54747;
}

.subscription-label {
  font-size: 1.25em;
  padding: 0em 0em 0.75em 0em;
}

@include medium-screen {
  .stepTwoContainer {
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    height: 65vh;
    padding: 1em;
    font-size: 1.5em;
  }
  .stepTwoGridItem {
    border-radius: 16px;
    margin: 1em;
  }
  .stepTwoGridItem > h4 {
    color: white;
    display: inline-block;
    padding-right: 50px;
    padding-bottom: 1em;
    padding-left: 5px;
    border-bottom: 2px solid white;
  }
  .stepTwoGridItem > button {
    position: absolute;
    bottom: 0.5em;
    border: none;
    border-radius: 16px;
    background-color: green;
    color: white;
    width: 50%;
  }
}
