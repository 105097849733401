@font-face {
  font-family: Montserrat;
  src: url(../../assets/fonts/montserrat_wght.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../../assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(../../assets/fonts/OpenSans-Regular.ttf);
}
.archived-modal {
  .MuiDialog-paperWidthSm {
    width: 600px;
    height: 400px;
    max-width: none;
    font-family: Montserrat;
    font-weight: 500;
  }
  .dialog-content {
    position: relative;
    top: 100px;
    .text-center {
      width: auto;
    }
    h3,
    h4,
    p {
      text-align: center;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      /* or 150% */
      text-align: center;
      letter-spacing: 0.12px;
      margin-top: 10px;
      color: #747171;
    }
  }
  .MuiButton-root {
    margin: 0 15px;
  }
  .MuiButton-contained {
    background-color: #fff;
  }
  .MuiButton-containedPrimary {
    background-color: #8fcaed;
    color: #333437;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #8fcaed;
  }
}

.not-enough-user-modal {
  .MuiDialog-paperWidthSm {
    width: auto !important;
    height: auto !important;
    max-width: none;
    font-family: MontserratBold;
  }
}

.edit-user-modal {
  .MuiDialog-paperWidthSm {
    width: 600px;
    height: 400px;
    max-width: none;
    font-family: MontserratBold;
  }
  .dialog-content {
    .text-center {
      width: auto;
      font-family: MontserratBold;
      font-size: 16.86px;
    }
    h3,
    h4,
    p {
      text-align: center;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      /* or 150% */
      text-align: center;
      letter-spacing: 0.12px;
      margin-top: 10px;
      color: #747171;
    }
    .btn-save {
      width: 191px;
    }
  }
  .MuiButton-root {
    text-transform: capitalize;
    margin: 0 15px;
    font-family: MontserratBold;
    font-size: 16.86px;
  }
  .MuiButton-contained {
    background-color: #fff;
  }
  .MuiButton-containedPrimary {
    background-color: #8fcaed;
    color: #333437;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #8fcaed;
  }
}
.action-text {
  color: rgba(51, 52, 55, 0.5);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0px 15px 0px 2px;
  font-family: OpenSans-Regular;
}

.viewOption {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.12px;
  padding-left: 10px;
  color: #333437;
  margin: 0px;
}

.loginStatusCanLogin {
  font-size: 12px;
  color: #3cc13b;
  width: 60px;
  margin-bottom: 0;
}

.loginStatusCantLogin {
  font-size: 12px;
  color: #e32c2c;
  width: 60px;
  margin-bottom: 0;
}
.loginStatusNotApplicable {
  position: relative;
  margin-bottom: 0;
  font-size: 12px;
  width: 120px;
}
.loginStatusNotApplicable__icon {
  position: absolute;
  display: flex;
  align-items: center;
  color: #98999a;
  top: -5px;
  right: 34px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  width: 5px;
  height: 16px;
  &:hover {
    &::before {
      content: "Login Status control only applies to the Patient account type.";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 51px;
      width: 174px;
      top: -15px;
      right: -180px;
      background: #ffffff;
      box-shadow: 0px 0px 21.1628px rgba(0, 0, 0, 0.12);
      border-radius: 5.29px;
      padding: 13px 9px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: black;
    }
  }
}
