.dialog-content {
  .MuiPaper-outlined {
    border: 2px solid rgba(0, 0, 0, 0.5);
  }
  .MuiButton-containedPrimary,
  .MuiButton-containedPrimary:hover {
    background-color: #8fcaed;
    color: #333437;
  }
  .MuiButton-outlined {
    background-color: #ffffff;
  }
  h3,
  h4 {
    text-align: left;
    font-weight: 600;
  }
  ul {
    padding-left: 1.3rem;
  }
  ul li {
    text-align: left;
  }
  .text-center a {
    text-decoration: underline;
    font-size: 18px;
    color: #2290d1;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    letter-spacing: 0.12px;
    margin-top: 10px;
    color: #747171;
  }
}
.stepper {
  width: auto;
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.02);
  box-shadow: inset 0.5px 0px 0px rgba(0, 0, 0, 0.07),
    inset 1px 0px 0px rgba(0, 0, 0, 0.02),
    inset -1px 0px 0px rgba(0, 0, 0, 0.02),
    inset -0.5px 0px 0px rgba(0, 0, 0, 0.07);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.51);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.MuiCircularProgress-svg {
  color: #c4dfef;
}
.view-imported {
  text-decoration: underline;
  font-size: 18px;
  color: #2290d1;
  cursor: pointer;
  margin: 5px;
}
