.importInProgress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 244px;
    margin: 70px 160px;
    gap: 50px;
    margin-bottom: 84px;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #333437;
    }
    .loading {
      width: 125.12px;
      height: 125.12px;
    }
    .footer {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.12px;
      color: #747171;
    }
    @media (max-width: 860px) {
      margin: 20px;
      .title {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
}
