.nf-navbar {
  background-color: #c4dfef !important;
  box-shadow: none !important;
  color: $bodyFontColor !important;

  .nf-header {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 600px) {
    .nf-header {
      min-height: 91px;
    }
  }
}

nav {
  .MuiPaper-root,
  .MuiDrawer-paper {
    background-color: $skyBlue;
    border: none;

    .MuiListItemIcon-root {
      margin: auto;
      .MuiSvgIcon-root {
        margin: auto;
      }

      .makeStyles-feedbackButton-9 {
        position: absolute;
        transform: translateY(5%);
        bottom: 5%;
      }
    }

    // .MuiDrawer-paperAnchorDockedLeft{
    //     background-color: red;
    // }
  }
}
.MuiDrawer-modal {
  .MuiDrawer-paperAnchorLeft {
    background-color: $skyBlue;
    border: none;

    .MuiListItemIcon-root {
      margin: auto;
      .MuiSvgIcon-root {
        margin: auto;
      }

      .makeStyles-feedbackButton-9 {
        position: absolute;
        transform: translateY(5%);
        bottom: 5%;
      }
    }
  }
}

// .MuiDrawer-paperAnchorLeft{
// }
