@font-face {
  font-family: OpenSans-Regular;
  src: url(../../assets/fonts/OpenSans-Regular.ttf);
}
.user-list-table {
  // background-color: red !important;
  // display: none;
  padding-top: 0rem;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // table{
  //     background-color: red !important;
  // }

  .MuiInput-underline:before,
  .MuiInput-underline:focus,
  .MuiInput-underline:after {
    border: none !important;
  }

  .MuiInputAdornment-positionStart {
    margin: auto 8px;
  }

  table {
    tr {
      td:first-child {
        div {
          button {
            margin: auto;
          }
        }
      }
    }

    .MuiTableCell-body,
    .MuiTypography-body1 {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 13px !important;
      letter-spacing: -0.2px !important;
      font-family: OpenSans-Regular !important;
    }

    td.MuiTableCell-root, th.MuiTableCell-root{
      padding: 16px 10px;
    }

    td.MuiTableCell-root:first-child, th.MuiTableCell-root:first-child{
      padding: 16px;
    }

    .MuiTableCell-head {
      background-color: #c4dfef !important;
      color: #2290d1 !important;
    }
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
  }

  .MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label {
    margin-right: 0;
  }

  fieldset,
  fieldset.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
  }
}

.nf-userlist-btn-container {
  // width: auto;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  margin-top: 5rem;
  justify-content: space-between;
}

.custom-table {
  margin-bottom: 5rem;
  .MuiTablePagination-toolbar {
    display: flex;
    justify-content: space-between;

    .MuiIconButton-root {
      display: none;
    }

    .MuiTablePagination-caption {
      display: block;
    }

    .MuiTablePagination-spacer {
      display: none;
    }
  }

  .MuiPaper-elevation2 {
    box-shadow: none;
  }

  .MuiToolbar-root {
    color: #000;
    padding: 0;
    margin-top: 20px;
    background-color: #e8f5fb;

    h6 {
      margin-right: 15px;
      font-size: 16px;
      color: #333437;
    }
    .MuiFormControl-root {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid !important;
    }

    [class^="MTableToolbar-spacer"] {
      display: none;
    }
  }

  @media (max-width: 599px) {
    div.filter-area {
      display: block !important;
    }
  }
}
// tilt container
// @media screen and (max-width: 599px){
//   .tilt-container{
//     max-height: 30rem;

//     .form-paper-container{
//       height: 100%;
//     }
//   }
// }
