.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #333437;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: #e2eff9;
  height: 200px;
  width: auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  text-align: center;
  letter-spacing: 0.12px;
  p {
    color: #747171;
    .ancher-file {
      text-decoration: underline;
      color: #2290d1;
      font-size: 14px !important;
    }
  }
}

@media (max-width: 600px) {
  .dropzone {
    padding: 13px;
  }
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
  overflow-wrap: break-word;
}

.upload-button {
  border: 1px solid #eeeeee !important;
  // background-color: #E2EFF9 !important;
}
.uploadFileModel {
  display: flex;
  margin: 0px 10px 10px 10px;
  gap: 10px;
}
@media (max-width: 700px) {
  .uploadFileModel {
    flex-direction: column;
  }
}
