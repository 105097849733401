@mixin medium-screen {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

// mobile first design (at mobile view 100%)
.app-container {
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
  width: 100%;
  float: right;
  min-height: 100vh;
  margin-top: 120px;
}

@font-face {
  font-family: Montserrat;
  src: url(../assets/fonts/montserrat_wght.ttf);
}

button {
  font-family: Montserrat;
}
.app-container-flex-item {
  width: 100%;
}

.MuiFab-root {
  box-shadow: none !important;
}

.MuiSelect-select {
    .MuiInputBase-input{
      color : #747171 ;
      padding: 6px 10px 6px 7px !important;
    }
    .MuiSelect-selectMenu{
      background: #FFFFFF;
      border-radius: 7px !important;
      border: 1px solid #ccc !important;
    }
    .MuiSelect-select:focus {
      background-color: #ffff;
    }
  }

// at bigger screen makes 85% content and 15% for the navbar (refer to navbar.scss)

@include medium-screen {
  .app-container {
    display: flex;
    flex: column nowrap;
    width: 83%;
    float: right;
    min-height: 100vh;
  }
}

.MuiInput-underline:before,
.MuiInput-underline:focus,
.MuiInput-underline:after {
  border: none !important;
}