.ConfirmImport-container {
  display: flex;
  justify-content: center;
  align-items: center;
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 244px;
    margin: 28px  160px;
    background: #e2eff9;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.48), 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    gap: 15px;
    padding: 10px;
    > div:first-child {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #333437;
    }
    > div:last-child {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #333437;
    }
    @media (max-width: 860px) {
      margin: 20px;
      > div:first-child {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
      }
      > div:last-child {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
